.page {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

select {
  height: 5vh;
}

.viewer {
  width: 100%;
  height: 95vh;
}
